 @import url("https://use.typekit.net/vyh1ekp.css");
/* https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
// $bg: #f8cb09; #yellow
$text: #222;
$accent: #e81e20;
$bg: #f8cb09;
// $bgLight: #fdf6e3;
$bgLight: #fdf6e3;
$borderWidth: 4px;
$paddingY: 0.35em;
$signatureFont: "rumba-extra", sans-serif;
// .site-content {
//   background: $black;
//   /* background: none; */
//   color: white;
// }
.knight.site-content {
  // background: $bg;
}

.knight {
  a.home-link {
    font-family: $signatureFont;
    font-size: 1.5em;
    color: $text;
    text-decoration: none;
    text-shadow: 0px 0px 5px #f8eadb;
    font-weight: 400;
    margin-left: 0.3em;

  }
  .cart.minimized {
    // background: rgb(244,1,1);
    // background: linear-gradient(90deg, rgba(244,1,1,1) 0%, rgba(255,89,89,1) 50%, rgba(180,5,5,1) 100%);
    background: white;
    padding-bottom: 0.3em;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
  }
  .landing-page-wrapper {
    h2, h3 {
      font-family: $signatureFont;
      text-align: center;
      padding: 1em 0.2em;
      font-size: 1.3em;
      color: #ffffff;
    }
    background: #1d252c;
  }

  .landing-page, .top-banner {
    font-family: $signatureFont;
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_fill,w_1200/tleg2mhbnm0koqkr2q7w);
    background-size: cover;
    background-position: center;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero-logo {
    margin: 0 auto;
    max-width: 350px;
    img {
      width: 100%;
    }
  }
  .landing-page .text {
    color: white;
    text-align: center;
    background: rgba(0,0,0,0.8);
    padding: 0.5em 0.8em;
    border-radius: 0.4em;
    .logo {
      width: 200px;
    }
    .large {
      font-size: 1.3em;
    }
    .cashback {
      font-family: Helvetica;
      font-style: italic;
      font-size: 0.7em;
      // background: #fdc109;
      // color: black;
      // padding: 0.2em 0.5em;
      display: inline-block;

      border-radius: 0.1em;
    }
  }

  a.checkout-button.button {
    font-family: $signatureFont;
    background: $bg;
    color: black;
    font-weight: 600;
    box-shadow: none;
    border: 2px solid black;

  }

  .category-name .text {
    font-family: $signatureFont;
    font-weight: 400;
    font-size: 2em;
    background: none;
    color: black;
    // border: 2.5px solid black;
    // padding: 0.5em 0.;
    border-radius: 0;
  }

  .top-banner {
    // height: 30vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
  }

  .pages-nav {
    font-family: $signatureFont;
    // background: linear-gradient(90deg, rgba(244,1,1,1) 0%, rgba(255,89,89,1) 50%, rgba(180,5,5,1) 100%);
    background: white;
    // border-top:    1px solid black;
    // border-bottom: 1px solid black;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    text-decoration: none;
    text-transform: lowercase;
    color: black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid #f10012;
    &:hover, &.active {
      border-bottom: $borderWidth solid $bg;
    }
  }

  a.order-online-link {
    display: inline-block;
    text-decoration: none;
    color: white;
    font-size: 0.7em;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $accent;
  }
  .ordering-header {
    background: rgba(0,0,0,0.5);
    padding: 0.3em 0.5em;
  }

  .ordering-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    // background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1540691096/PablitosKitchen_Hero-1_FullSize_blog_960x540_sc2pmh.jpg);
    background-size: cover;
    background-position: center bottom;
    text-align: center;
    font-family: "Chewy";
    color: white;
    // height: 300px;
  }
}
